import React from 'react';
import Layout from '../../components/Layout/Layout';
import FaqItem from '../../components/KoralgoForSeller/Faq/FaqItem/FaqItem';
import { FAQ_PAGE } from '../../constans/faqData';
import styles from './index.module.scss';

const Index = () => (
  <Layout mainClasses="section-padded-wrapper flex-grow-1">
    <div className="info-page-container">
      <div className={`${styles.title} h4-title`}>Pricing & FAQ</div>
      {FAQ_PAGE.map((faqItem, index) => (
        <FaqItem
          wrapperStyles={styles.faqItemWrapper}
          titleWrapperStyles={styles.faqItemTitleWrapper}
          iconStyles={styles.icon}
          key={`seer${index}`}
          title={faqItem.title}
          paragraph={faqItem.paragraph}
        />
      ))}
    </div>
  </Layout>
);

export default Index;
