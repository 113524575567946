/* eslint-disable max-len */
import React from 'react';
import { Link } from 'gatsby';
import {
  KOREVAL_LINK,
  CONTACTS_LINK,
} from './links';

export const FAQ_PAGE = [
  {
    title: 'About us',
    paragraph: (
      <>
        <p>
          We research and rate properties & activities and publish a comprehensive
          review which you can read. Our proprietary algorithms then
          rank options and optimize travel itineraries based on your personal
          preferences, interests, and budget.
        </p>
        <p>
          We are also different because:
        </p>
        <div className="ml-15">
          <div>
            - Environmental & Social aspects are rated and part of the valuation;
          </div>
          <div>
            - Unlike other platforms, we do not mislead our users by placing
            sponsored offers at the top of the rankings.
          </div>
        </div>
      </>
    ),
  },
  {
    title: 'Profile set-up',
    paragraph: (
      <>
        <p>
          Think of your profile as part of the search parameters: the more
          accurate it is and the more relevant the
          results will be. This is why we recommend that you take a couple of
          minutes electing the degree of importance
          of
          each “category” or feature.
          Just in case this is in your mind, we are not selling your data to
          anyone.
        </p>
        <p>
          We will also provide signed-up users with the ability to set, save and use
          several profiles so that depending on
          the
          context of your booking or purchase, you can simply apply the most
          relevant profile. This way you will be able to create a family account.
        </p>
      </>
    ),
  },
  {
    title: 'Reviews and ratings',
    paragraph: (
      <>
        <p>
          Koreval, a business division of Koralgo, uses Review Analysts to carry
          out an extensive search through the internet and compiles relevant information,
          including the essence of online customer reviews.
        </p>
        <p>
          There are two types of reviews:
        </p>
        <div className="ml-15">
          <div>
            - Desktop where no site visit takes place, and the analyst relies on information
            that is publicly available information and any materials provided by the Seller;
          </div>
          <div>
            - Onsite which includes a formal visit of the analyst to the property or activity
            and a meeting with the Seller management.
          </div>
        </div>
        <p />
        <p>
          Please use this
          {' '}
          <Link className="inlined-link" to={CONTACTS_LINK}>contact form</Link>
          {' '}
          if you are interested in Koreval services.
        </p>
      </>
    ),
  },
  {
    title: 'Itinerary and Review Packs pricing',
    paragraph: (
      <>
        <p>
          The current pricing before applicable taxes and Stripe fees is:
        </p>
        <div className="ml-15">
          <div>
            - US$1.00 per Itinerary exported;
          </div>
          <div>
            - US$0.25 per Review exported as part of an Itinerary Pack or a Review Pack
          </div>
        </div>
        <p />
        <p>
          Those unit prices are subject to a minimum price per pack of US$3.50 before
          fees and taxes.
        </p>
      </>
    ),
  },
  {
    title: 'Payment options and fees',
    paragraph: (
      <>
        <p>
          The platform uses Stripe to process payments in a secured
          and efficient fashion using the most popular payment medium
          such as Visa, Mastercard or American Express Credit Card,
          Google Pay and Apple Pay as well as dozens of popular
          payment methods around the world such as Alipay or WeChat Pay.
        </p>
        <p>
          The distribution fee applicable for every new booking
          or purchase is charged to the supplier and is the lowest
          in the industry which ultimately results in either lower
          price of more features for the consumer.
        </p>
        <div>
          See below for indicative Strip pricing
        </div>
        <div className="ml-15">
          <div>
            - In the USA:
            <a rel="noreferrer" target="_blank" href="https://stripe.com/pricing" className="ml-10 inlined-link">
              https://stripe.com/pricing
            </a>
          </div>
          <div>
            - In the European Union:
            <a
              rel="noreferrer"
              target="_blank"
              href="https://stripe.com/en-fr/pricing"
              className="ml-10 inlined-link"
            >
              https://stripe.com/en-fr/pricing
            </a>
          </div>
          <div>
            - In Singapore:
            <a rel="noreferrer" target="_blank" href="https://stripe.com/en-sg/pricing" className="ml-10 inlined-link">
              https://stripe.com/en-sg/pricing
            </a>
          </div>
        </div>
      </>
    ),
  },
  {
    title: 'Booking edits, cancellation and dispute',
    paragraph: (
      <>
        <p>
          Depending on the terms and conditions of your existing
          booking, you may be able to edit or cancel your booking
          directly using the link in your email confirmation or,
          if you are a signed up user and logged in, from your dashboard.
        </p>
        <p>
          For cancellation, the relevant terms and conditions will
          apply so that the amount to be refunded shall be computed accordingly
        </p>
        <p>
          For edits, likewise the relevant terms and conditions will
          apply as well as the prevailing offer price so that some
          additional payment may in some instance need to be made.
        </p>
        <p>
          Note that Koralgo operates as an infrastructure and service
          provider, not a middleman, so that in no case will it be
          involved in dispute resolution, other than facilitating
          communication with suppliers and implementing the agreed solution.
        </p>
      </>
    ),
  },
  {
    title: 'Help / contact',
    paragraph: (
      <>
        Please use this contact form
        {' '}
        <Link className="inlined-link" to={CONTACTS_LINK}>here</Link>
        {' '}
        if you require assistance
      </>
    ),
  },
];
